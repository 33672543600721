import React from 'react';
import Markdown from 'react-markdown'
import { Product } from '../customInterfaces';

const Chatbox = ({ message_type, text,links,products,hasProducts  }: { message_type: string, text: string,links:string[],products:Product[],hasProducts:boolean  }) => {
    return (
        <div className={message_type === "Bot" ? "chatbox botMessage" : "chatbox UserMessage"}>
            <div><Markdown>{text}</Markdown></div>
            <h6 style={{display:links.length > 0 ? "block":"none"}} >References</h6>
            {links.map((link)=><div><a href={link}>{link}</a></div>)}
        </div>
    );
}



export default Chatbox;

