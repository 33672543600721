import React, { useEffect } from 'react';
import logo from './bot.png';
import './App.css';
import Chatbox from './components/Chatbox';
// import closeIcon from './close_icon.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { Button, IconButton, LinearProgress, Stack, Typography } from '@mui/material';
import { Send, Close, RestartAlt } from "@mui/icons-material";
import { MessageOptions, Product } from './customInterfaces';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import OpenAI from "openai";

declare global {
  interface Window {
    avatarName: any;
  }
}

const theme = createTheme({
  palette: {
    primary: {
      main: "#70ad47",
      contrastText: "white"
    },
    secondary: {
      main: "#568fd2",
    },
  },
});
const openai = new OpenAI({ apiKey: "sk-proj-RjrGXAts8tZwdG4IaFbHT3BlbkFJXavdfM3JMeTDU9BMCYbk", dangerouslyAllowBrowser: true });



function App() {

  const API_URL = "https://indonesia-asks-adaptive-sucking.trycloudflare.com/app/chatbot/invoke"
  const MESSAGE_TYPES = {
    BOT: "Bot",
    USER: "User",
    ACTION: "Action"
  }
  const [messages, setMessages] = React.useState([{
    message_type: MESSAGE_TYPES.BOT,
    text: "Hi there! How can I help you today?",
    products: Array(),
    hasProducts: false,
    options: Array(),
    links: Array()
  },
  {
    message_type: MESSAGE_TYPES.ACTION,
    text: "What does the latest research in my file say about managing Diabetes?",
    products: [],
    hasProducts: false,
    options: [],
    links: []
  },
  {
    message_type: MESSAGE_TYPES.ACTION,
    text: "Based on the file, how can we differentiate between Type 1 and Type 2?",
    products: [],
    hasProducts: false,
    options: [],
    links: []
  },
  {
    message_type: MESSAGE_TYPES.ACTION,
    text: "Please suggest few treatment options.",
    products: [],
    hasProducts: false,
    options: [],
    links: []
  },
  ]);

  const [miniMized, setMinimized] = React.useState(true);
  const [avatarName, setAvatarName] = React.useState("");
  const [avatarUrl, setAavatarUrl] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [inputval, setInputVal] = React.useState("");
  const [assistant, setAssitant] = React.useState<OpenAI.Beta.Assistants.Assistant>();
  const [ai_thread, setThread] = React.useState("");
  const fetchdata = async () => {
    const myAssistant = await openai.beta.assistants.retrieve(
      "asst_jKtR4LpDQQJkyNHsB7N5LLCM"
    );
    setAssitant(myAssistant);
  }

  useEffect(() => {
    fetchdata();
    const avatarName = localStorage.getItem('avatarName') || "Aevus Ai";
    const avatarUrl = localStorage.getItem('avatarUrl') || logo;
    setAvatarName(avatarName);
    setAavatarUrl(avatarUrl);

    const handleStorage = () => {
      const newAvatarName = localStorage.getItem('avatarName') || "";
      const newAvatarURl = localStorage.getItem('avatarUrl') || "";
      setAavatarUrl(newAvatarURl);
      setAvatarName(newAvatarName);
    }

    window.addEventListener('storage', handleStorage);

    return () => {
      window.removeEventListener('storage', handleStorage);
    }
  }, []);


  const resetMessages = async () => {
    setMessages(messages.slice(0, 4));
    if (ai_thread !== ""){
      //delete thread
      const response = await openai.beta.threads.del(ai_thread);
      setThread("");
    }
  }

  const toggleMinimize = () => {
    setMinimized(!miniMized);
  };
  const sendChat = async (conversation: { message_type: string, text: string, products: Product[], hasProducts: boolean, options: any[], links: any[] }[]) => {
    setLoading(true);
    const filtered_conversation = conversation.filter((message) => message.message_type !== MESSAGE_TYPES.ACTION).map((m) => {
      return {
        role: m.message_type === MESSAGE_TYPES.BOT ? "assistant" : "user",
        content: m.text
      }
    })

    let run;
    if (ai_thread == "") {
      run = await openai.beta.threads.createAndRun({
        assistant_id: "asst_jKtR4LpDQQJkyNHsB7N5LLCM",
        thread: {
          messages: (filtered_conversation as any),
        },
      });
    } else {
      run = await openai.beta.threads.runs.create(
        ai_thread,
        {
          assistant_id: "asst_jKtR4LpDQQJkyNHsB7N5LLCM",
          additional_messages: filtered_conversation.slice(-1).map(m => ({ role: "user", content: m.content }))
        }
      );
    }
    setThread(run.thread_id);
    let runStatus = run.status;
    while (true) {
      if (runStatus === 'completed' || runStatus === 'failed') {
        break;
      }

      await new Promise(resolve => setTimeout(resolve, 1000));
      run = await openai.beta.threads.runs.retrieve(run.thread_id, run.id);
      runStatus = run.status;
      console.log(runStatus)
    }
    //get the thread 
    const threadMessages = await openai.beta.threads.messages.list(run.thread_id);
    const ai_resp = (threadMessages.data[0].content[0] as any).text.value

    setMessages([...conversation, {
      message_type: MESSAGE_TYPES.BOT,
      text: ai_resp,
      products: [],
      hasProducts: false,
      options: [],
      links: []
    }])
    setLoading(false);
  }
  const handleUserAction = async (message: string) => {
    const newMessage = { message_type: MESSAGE_TYPES.USER, text: message, products: [], hasProducts: false, options: [], links: [] };
    const all_messages = [...messages, newMessage];
    setMessages(all_messages);
    sendChat(all_messages);
  }
  const handleSendClick = () => {
    let all_messages: any = [];
    if (inputval !== "") {
      all_messages = [...messages, { message_type: MESSAGE_TYPES.USER, text: inputval, links: [], products: [] }]
      setInputVal("");
      setMessages(all_messages);
    }
    sendChat(all_messages);
  }
  return (
    <ThemeProvider theme={theme}>

      <div className={miniMized ? "botContainerMinimized" : "botContainer"}>
        {miniMized ?
          <button className='botIconButton' style={{ padding: "20px", translate: "-20px -19px" }} onClick={toggleMinimize}>
            <img src={avatarUrl} style={{ width: "50px" }} width={"50px"} height={"50px"} alt="allgpt" />
          </button> : null}
        <div className='headerContainer'>
          <div className='logoContainer'>
            {/* <img src={logo} width={"40px"} alt="logo" /> */}
            <button className='botIconButton' onClick={toggleMinimize}>
              <img src={avatarUrl} style={{width:"40px"}} width={"40px"} height={"40px"} alt="allgpt" />
            </button>
            <Typography variant="h6" className="avtarName" style={{ paddingLeft: "5px" }}>{avatarName}</Typography>
          </div>
          <Stack alignItems="flex-start" direction={"row"} justifyContent={"center"}>
            <IconButton sx={{ textTransform: "none", color: "white" }} color="primary" onClick={resetMessages}> <RestartAlt></RestartAlt> </IconButton>
            <IconButton sx={{ color: "white" }} onClick={toggleMinimize} ><Close></Close> </IconButton>

          </Stack>
        </div>
        <div className='messages' style={{display:miniMized?"none":"flex"}}>
          {messages.map((message, index) => (
            message.message_type === MESSAGE_TYPES.ACTION ? <Button variant="outlined" sx={{ margin: "0.2rem", textTransform: "inherit" }} key={index} className="actionButton" onClick={() => handleUserAction(message.text)} >{message.text}</Button> :
              <Chatbox key={index}
                message_type={message.message_type}
                text={message.text}
                links={message.links}
                products={message.products}
                hasProducts={message.hasProducts}
              />
          ))}
        </div>

          <Stack sx={{ display: loading ? "block" : "none" }} direction="column" spacing={2}>
            <LinearProgress color="primary" variant='query'></LinearProgress>
          </Stack>
          <div className="input-container">
            <input value={inputval} onChange={(e) => setInputVal(e.target.value)} type="text" className="user-input" />
            <Button variant="contained"
              disabled={loading ? true : false}
              color="secondary"
              sx={{ marginLeft: 1 }}
              onClick={handleSendClick}
              endIcon={<Send></Send>}>Send</Button>
          </div>
      </div>
    </ThemeProvider>

  );

}



export default App;
